import React, { useState, useEffect } from "react";
import "./CaseStudy.css";
import AnnouncementBar from "../../shared/AnnouncementBar";
import {
  MdDescription,
  MdOutlineReportProblem,
  MdAutoFixHigh,
  MdCloudDone,
} from "react-icons/md";
import { IoMdClose, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  classPlantOne,
  classPlantTwo,
  classPlantThree,
  plantDesignOne,
  plantDesignTwo,
} from "../../../assist/index";
import { Helmet } from "react-helmet-async";
import pageTransition from "../../../variants/pageTransition";

const classCaseStudies = [
  {
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis. Suspendisse convallis quam eget enim fermentum, vitae suscipit dui convallis.",
    challenge:
      "Phasellus sed dolor vitae turpis congue molestie. Donec eu risus eu orci semper ullamcorper. Phasellus et orci eget lacus vulputate mollis. Integer egestas interdum massa. Aliquam ac felis ultricies, vestibulum mauris a, ornare nisl.",
    solution:
      "Praesent dapibus enim quis nunc fringilla, vitae gravida arcu aliquam. Sed finibus pulvinar urna, at commodo dolor sodales. Maecenas sed est efficitur, hendrerit risus ac, pretium ante. Vivamus erat nulla, dictum ut ante quis.",
    outcome:
      "Duis diam diam, consequat et nibh in, tempus lacinia lacus. Nulla facilisi. Mauris fermentum, felis vehicula congue pellentesque, tellus sapien pulvinar nunc, sit amet maximus sapien augue eu lectus. Ut vitae ligula nisl. Donec sed dignissim ligula.",
  },
];

const IndividualCaseStudy = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [imagesPerView, setImagesPerView] = useState(3);
  const [modal, setModal] = useState({
    isOpen: false,
    imgSrc: "",
    isClosing: false,
  });

  const images = [
    classPlantOne,
    classPlantTwo,
    classPlantThree,
    plantDesignOne,
    plantDesignTwo,
  ];

  // Adjust totalSlides calculation
  const totalSlides = images.length - imagesPerView + 1;

  const openModal = (imgSrc) => {
    setModal({ isOpen: true, imgSrc, isClosing: false });
  };

  const closeModal = () => {
    setModal((prevState) => ({ ...prevState, isClosing: true }));
    setTimeout(() => {
      setModal({ isOpen: false, imgSrc: "", isClosing: false });
    }, 300); // Match the animation duration
  };

  const nextImage = () => {
    if (currentSlide < totalSlides - 1) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };

  const prevImage = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  useEffect(() => {
    const updateImagesPerView = () => {
      if (window.innerWidth <= 768) {
        setImagesPerView(1);
      } else {
        setImagesPerView(3);
      }
    };

    window.addEventListener("resize", updateImagesPerView);
    updateImagesPerView(); // Set initial value

    return () => window.removeEventListener("resize", updateImagesPerView);
  }, []);

  return (
    <>
      <Helmet>
        <title>Individual Case Study</title>
        <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis suspendisse." />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="case-hero hero">
          <div className="hero-content">
            <h1>Individual Case Study</h1>
          </div>
        </div>
      </div>
      <section>
        <div className="carousel case-images">
          {images.length > imagesPerView && (
            <IoIosArrowBack
              className={`arrow left ${currentSlide === 0 ? "disabled" : ""}`}
              onClick={prevImage}
            />
          )}
          <div
            className="carousel-inner"
            style={{
              width: `${(images.length * 100) / imagesPerView}%`,
              transform: `translateX(-${
                (currentSlide * 100) / imagesPerView
              }%)`,
            }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                onClick={() => openModal(image)}
                style={{ width: `${(100 * imagesPerView) / images.length}%` }}
              />
            ))}
          </div>
          {images.length > imagesPerView && (
            <IoIosArrowForward
              className={`arrow right ${
                currentSlide === totalSlides - 1 ? "disabled" : ""
              }`}
              onClick={nextImage}
            />
          )}
        </div>
      </section>
      {modal.isOpen && (
        <div
          className={`modal ${modal.isClosing ? "fade-out" : "fade-in"}`}
          onClick={closeModal} // Close modal when clicking on the background
        >
          <div
            className={`modal-content ${
              modal.isClosing ? "zoom-out" : "zoom-in"
            }`}
            onClick={(e) => e.stopPropagation()} // Prevent propagation for clicks inside modal
          >
            <span className="close" onClick={closeModal}>
              <IoMdClose />
            </span>
            <img src={modal.imgSrc} alt="Enlarged view" />
          </div>
        </div>
      )}
      <section className="card-container">
        {classCaseStudies.map((study, index) => (
          <div key={index} className="card">
            <div className="card-item">
              <MdDescription className="icon" />
              <h2>Description</h2>
              <p>{study.description}</p>
            </div>
            <div className="card-item">
              <MdOutlineReportProblem className="icon" />
              <h2>Challenge</h2>
              <p>{study.challenge}</p>
            </div>
            <div className="card-item">
              <MdAutoFixHigh className="icon" />
              <h2>Solution</h2>
              <p>{study.solution}</p>
            </div>
            <div className="card-item">
              <MdCloudDone className="icon" />
              <h2>Outcome</h2>
              <p>{study.outcome}</p>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default pageTransition(IndividualCaseStudy);
