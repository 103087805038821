import React from 'react';
import './Blog.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import { blogImgOne, blogImgTwo, blogImgThree } from '../../../assist/index';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import pageTransition from '../../../variants/pageTransition';

const blogPosts = [
  {
      imgSrc: blogImgOne,
      altText: "10 Low-Maintenance Indoor Plants for Beginners",
      title: "10 Low-Maintenance Indoor Plants for Beginners",
      date: "May 17, 2024"
  },
  {
      imgSrc: blogImgTwo,
      altText: "How to Revive a Dying Houseplant",
      title: "How to Revive a Dying Houseplant",
      date: "April 05, 2024"
  },
  {
      imgSrc: blogImgThree,
      altText: "Best Air-Purifying Plants for a Healthier Home",
      title: "Best Air-Purifying Plants for a Healthier Home",
      date: "February 28, 2024"
  },
  {
      imgSrc: blogImgThree,
      altText: "Succulent Care Made Easy",
      title: "Succulent Care Made Easy",
      date: "February 28, 2024"
  },
  {
      imgSrc: blogImgThree,
      altText: "5 Fast-Growing Plants to Transform Your Garden",
      title: "5 Fast-Growing Plants to Transform Your Garden",
      date: "February 28, 2024"
  },
  {
      imgSrc: blogImgThree,
      altText: "Secret to Keeping Your Fiddle Leaf Fig Happy",
      title: "Secret to Keeping Your Fiddle Leaf Fig Happy",
      date: "February 28, 2024"
  }
];


const Blog = () => {

  /*
  const maxTitleLength = 25; 
  const textLimit = (title) => title.length > maxTitleLength ? `${title.slice(0, maxTitleLength)}...` : title;
  */

  return (
    <div>
      <Helmet>
        <title>React Modern Theme | Blog</title>
        <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis suspendisse." />
      </Helmet>
      <AnnouncementBar />
      <div className="blog-hero hero">
          <div className="hero-content">
            <h1>
                The Potted Post
            </h1>
        </div>
      </div>
      <section>
          <div className="blog-pottedposts">
              {blogPosts.map(post => (
                  <Link key={post.altText} to="/individual-blog" className="pottedpost"  reloadDocument>
                      <div className="blog-image">
                        <img src={post.imgSrc} alt={post.altText}/>
                      </div>
                      <div className="potted-blog-content">
                          <p>{post.date}</p>
                          <h2 title={post.title}>{(post.title)}</h2>
                      </div>
                  </Link>
              ))}
          </div>
        </section>
    </div>
  );
};


export default pageTransition(Blog);
