import React from 'react';
import './Body.css';
import HeroSection from './Herosection';
import Shopsection from './Shopsection';
import PromoSection from './Promosection';
import ShopBysection from './shopBysection';
import BlogSection from './BlogSection';
import Caretips from './Caretips';
import PickupInfo from './Pickupinfo';
import { Helmet } from 'react-helmet-async';
import pageTransition from '../../variants/pageTransition';


const Body = () => {
    return (
        <>
            <Helmet>
                <title>React Modern Theme</title>
                <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis suspendisse." />
            </Helmet>
            <HeroSection />
            <Shopsection />
            <ShopBysection />
            <PromoSection />
            <BlogSection />
            <Caretips />
            <PickupInfo />
        </>
    );
}

export default pageTransition(Body);