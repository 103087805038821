import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/layouts/navbar/Navbar';
import Body from './components/body/Body';
import Footer from './components/layouts/footer/Footer';
import Contact from './components/pages/contact/Contact';
import Blog from './components/pages/blog/Blog';
import AnnouncementBar from './components/shared/AnnouncementBar';
import BlogSidebar from './components/shared/BlogSidebar';
import About from './components/pages/about/About';
import Legal from './components/pages/legal/Legal';
import Privacy from './components/pages/privacy/Privacy';
import AllServices from './components/pages/allServices/AllServices';
import AllCaseStudies from './components/pages/allCaseStudies/AllCaseStudies';
import Service from './components/pages/services/Service';
import IndividualCaseStudy from './components/pages/caseStudies/IndividualCaseStudy';
import PlantDirectory from './components/pages/plantDirectory/PlantDirectory';
import IndividualPlant from './components/pages/individualPlant/IndividualPlant';
import IndividualBlog from './components/pages/individualBlog/IndividualBlog';


function App() {
  return (
    <HelmetProvider> 
      <Router>
        <Navbar />
          <Routes>
            <Route path="/" element={<Body />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/plant-directory" element={<PlantDirectory />} />
            <Route path="/individual-plant" element={<IndividualPlant />} />
            <Route path="/individual-blog" element={<IndividualBlog />} />
            <Route path="/all-services" element={<AllServices />} />
            <Route path="/all-case-studies" element={<AllCaseStudies />} />
            <Route path="/service" element={<Service />} />
            <Route path="/case-study" element={<IndividualCaseStudy />} />
          </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
