// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assist/images/about/about-heroImg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-hero {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.about-feature {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: -8px;
}

.about-feature:nth-child(odd) .about-feature-image {
    order: 2;
}

.about-feature-image {
    flex: 1 1;
    overflow: hidden;
}

.about-feature-image,
.about-feature-text {
    width: 50%;
}

.about-feature-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.about-feature-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-feature-text p {
    max-width: 85%;
    font-size: 1.2rem;
}


@media (max-width: 991px) {
    .about-feature {
        display: flex;
        flex-direction: column;
        gap:2em;
        margin-bottom:2em;
    }
    .about-feature:nth-child(odd) .about-feature-image {
        order:1;
    }
    .about-feature:nth-child(odd) .about-feature-text {
        order:2
    }
    .about-feature-image, .about-feature-text {
        width:100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/about/About.css"],"names":[],"mappings":"AAAA;IACI,yDAAuE;AAC3E;AACA;IACI,aAAa;IACb,oBAAoB;IACpB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,SAAO;IACP,gBAAgB;AACpB;;AAEA;;IAEI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;;AAGA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,OAAO;QACP,iBAAiB;IACrB;IACA;QACI,OAAO;IACX;IACA;QACI;IACJ;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".about-hero {\n    background-image: url('../../../assist/images/about/about-heroImg.jpg');\n}\n.about-feature {\n    display: flex;\n    align-items: stretch;\n    justify-content: space-between;\n    margin-bottom: -8px;\n}\n\n.about-feature:nth-child(odd) .about-feature-image {\n    order: 2;\n}\n\n.about-feature-image {\n    flex: 1;\n    overflow: hidden;\n}\n\n.about-feature-image,\n.about-feature-text {\n    width: 50%;\n}\n\n.about-feature-image img {\n    width: 100%;\n    height: auto;\n    object-fit: cover;\n}\n\n.about-feature-text {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n}\n\n.about-feature-text p {\n    max-width: 85%;\n    font-size: 1.2rem;\n}\n\n\n@media (max-width: 991px) {\n    .about-feature {\n        display: flex;\n        flex-direction: column;\n        gap:2em;\n        margin-bottom:2em;\n    }\n    .about-feature:nth-child(odd) .about-feature-image {\n        order:1;\n    }\n    .about-feature:nth-child(odd) .about-feature-text {\n        order:2\n    }\n    .about-feature-image, .about-feature-text {\n        width:100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
