import React from 'react';
import './Contact.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import pageTransition from '../../../variants/pageTransition';

const Contact = () => {
  return (
    <div>
        <Helmet>
            <title>React Modern Theme | Contact Us</title>
            <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis suspendisse." />
        </Helmet>
        <AnnouncementBar />
        <div className="contact-hero hero">
            <div className="contact-hero-content">
                <h1>
                    Contact Us
                </h1>
            </div>
        </div>
        <section>
            <div className="container">
                <div className="contact-container">
                    <h2>SEND US AN EMAIL</h2>
                    <form>
                        <div className="input-row">
                            <div className="input-group">
                                <label for="name">Name</label>
                                <input type="text" id="name" name="name" placeholder="Your Name" required/>
                            </div>
                            <div className="input-group">
                                <label for="email">Email</label>
                                <input type="email" id="email" name="email" placeholder="Your Email" required/>
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="input-group">
                                <label for="message">Message</label>
                                <textarea id="message" name="message" placeholder="Your Message" required></textarea>
                            </div>
                        </div>
                        <button type="submit" className="button">SEND</button>
                    </form>
                </div>              
            </div>
        </section>
        <section className="pickup-info">
            <div className="pickup-info-overlay">
                <h3>View Pickup and Delivery Options</h3>
                <p>M - F: 9:00am - 6:00pm</p>
                <Link className="button" to="/contact">More Info</Link>
            </div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.456324194087!2d-79.39477718450073!3d43.66872927912124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34b0fcaa03b7%3A0xcc2b1658740928a2!2sToronto!5e0!3m2!1sen!2sca!4v1622484070764!5m2!1sen!2sca"
                width="600" height="450" allowFullScreen="" loading="lazy" aria-label="Location map">
            </iframe>
        </section>
    </div>
  );
};

export default pageTransition(Contact);