import React from 'react';
import './Legal.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import { Helmet } from 'react-helmet-async';
import pageTransition from '../../../variants/pageTransition';

const Legal = () => {
  return (
    <>
      <Helmet>
        <title>React Modern Theme | Terms of Service</title>
        <meta
          name="description"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis suspendisse."
        />
      </Helmet>
      <section className="legal-content">
        <h1>Terms of service</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
          fermentum felis quis justo placerat, nec imperdiet nunc tempus. Donec
          sed elit nec urna scelerisque mattis at sit amet quam. Fusce maximus
          arcu et metus consequat, et gravida nibh venenatis.
        </p>
        <p>
          Aenean aliquet sem in lectus vulputate, at elementum ipsum consequat.
          Nunc consequat felis libero, sed congue neque venenatis eget.
          Suspendisse cursus enim vel felis porttitor rhoncus. In non justo a
          elit gravida imperdiet.
        </p>
        <h2>Section Two</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
          pharetra lacinia congue. Nulla facilisi. Aliquam erat volutpat. Cras
          auctor neque eu lectus semper, id imperdiet dui ultrices. Vivamus
          aliquam nisi a tellus interdum congue.
        </p>
        <p>
          Aliquam erat volutpat. Pellentesque suscipit egestas nunc, quis
          molestie neque tincidunt imperdiet. Etiam et nisi nec erat porttitor
          mattis at eu nisi. Mauris pellentesque est sed malesuada rhoncus.
          Proin maximus metus in nulla ornare rutrum. Suspendisse bibendum
          aliquet blandit.
        </p>
        <h2>Section Three</h2>
        <p>
          Sed laoreet pellentesque leo sed volutpat. Nam efficitur, odio at
          euismod gravida, metus turpis faucibus urna, eu malesuada nulla sapien
          sed lorem. Proin nec risus eget augue tincidunt condimentum. Cras eu
          mi odio. Proin efficitur neque id libero commodo, vel consectetur arcu
          euismod. Suspendisse id lacus interdum, consectetur ante sit amet.
        </p>
        <p>
          Vivamus ornare quam non lacinia mattis. Cras imperdiet ligula in neque
          auctor, id hendrerit nunc facilisis. Aliquam varius ligula vel leo
          luctus, a molestie tellus dignissim. Curabitur at viverra nisi.
        </p>
        <h2>Section Four</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu odio
          vel mauris malesuada finibus in in libero. Nam eleifend urna non ipsum
          efficitur, malesuada vulputate lorem porttitor. Mauris ac porta lacus,
          vitae sagittis sem.
        </p>
        <p>
          Aliquam eget dictum turpis. Quisque sit amet ex id risus sodales
          lobortis non placerat risus. Vestibulum in elit mollis, egestas nulla
          vel, consectetur mi. Vivamus posuere, orci non commodo tempor, diam
          metus tristique magna, et dignissim risus lacus nec purus. Morbi
          vulputate ultrices sem, nec dapibus nulla egestas eget. Aenean mollis
          quis est eget rhoncus. Interdum et malesuada fames ac ante ipsum
          primis in faucibus.
        </p>
      </section>
    </>
  );
}

export default pageTransition(Legal);