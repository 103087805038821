import React from 'react';
import { Link } from 'react-router-dom';
import { plantInstallSlideOne,
        plantClassSlideOne,
        plantHealthOne,
        plantDesignOne
} from '../../assist/index';
import './Body.css'

const shopByItems = [
    {
        imgSrc: plantClassSlideOne,
        altText: "Service Bundle One",
        linkTo: "/service",
        buttonText: "Service Bundle One"
    },
    {
        imgSrc: plantDesignOne,
        altText: "Service Bundle Two",
        linkTo: "/service",
        buttonText: "Service Bundle Two"
    },
    {
        imgSrc: plantHealthOne,
        altText: "Service Bundle Three",
        linkTo: "/service", 
        buttonText: "Service Bundle Three"
    },
    {
        imgSrc: plantInstallSlideOne,
        altText: "Service Bundle Four",
        linkTo: "/service",
        buttonText: "Service Bundle Four"
    }
];

const shopBysection = () => {
  return (
    <>
        <section className="services">
            <h2 className="h1">Services</h2>
            <Link className="button" to="/all-services" reloadDocument>View All Services</Link>
            <div className="service-items">
                {shopByItems.map(item => (
                    <div key={item.altText} className="service-item" style={{ backgroundImage: `url(${item.imgSrc})` }}>
                        <div className="service-item-content">
                            <Link className="button" to={item.linkTo} reloadDocument>{item.buttonText}</Link>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    </>
  )
}

export default shopBysection