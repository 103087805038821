import React from 'react';
import './About.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import { aboutImgOne, aboutImgTwo, aboutImgThree } from '../../../assist/index';
import { Helmet } from 'react-helmet-async';
import pageTransition from '../../../variants/pageTransition';

const aboutFeatures = [
  {
    src: aboutImgOne,
    alt: 'Aliquam eget dictum turpis',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed nulla metus. Nam efficitur purus purus, ut bibendum sapien commodo sed. Suspendisse potenti. Nam non elementum ex, sed malesuada diam. Cras malesuada turpis ut ante laoreet ultricies.'
  },
  {
    src: aboutImgTwo,
    alt: 'Aliquam eget dictum turpis',
    text: 'Nam id vestibulum arcu, sit amet mattis urna. Suspendisse et sem in nibh gravida lacinia. Cras luctus magna ipsum, molestie malesuada neque suscipit nec. Nulla facilisi. Donec at ullamcorper lorem. Integer neque leo, vestibulum ultrices tellus.'
  },
  {
    src: aboutImgThree,
    alt: 'Aliquam eget dictum turpis',
    text: 'Nulla eu dolor ut quam consequat posuere. Etiam et accumsan metus. Vivamus dolor mauris, fringilla non elementum ut, bibendum a ligula. Quisque maximus leo vitae felis malesuada venenatis. Sed augue eros, ultrices ut orci sit amet.'
  }
];

const About = () => {
  return (
    <>
    <Helmet>
      <title>React Modern Theme | Our Story</title>
      <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis suspendisse." />
    </Helmet>
    <AnnouncementBar />
    <div className="about-hero hero">
      <h1>Our Story</h1>
    </div>
    <section>
      <div className="container">
        {aboutFeatures.map((feature, index) => (
          <div key={index} className="about-feature">
            <div className="about-feature-image">
              <img src={feature.src} alt={feature.alt} />
            </div>
            <div className="about-feature-text">
              <p>{feature.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
    </>
  );
}

export default pageTransition(About);
;
