import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './allServices.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import {
  serviceImgOne,
  serviceImgTwo,
  serviceImgThree,
  serviceImgFour,
  serviceImgFive,
  serviceImgSix
} from '../../../assist/index';
import pageTransition from '../../../variants/pageTransition';

const serviceInfo = [
    {
      img: serviceImgOne,
      title: "Service Bundle One",
      description: "Duis diam diam, consequat et nibh in, tempus lacinia lacus. Nulla facilisi. Mauris fermentum, felis vehicula congue pellentesque, tellus sapien pulvinar nunc, sit amet maximus sapien augue eu lectus.",
      path: "/service"
    },
    {
      img: serviceImgTwo,
      title: "Service Bundle Two",
      description: "Integer egestas interdum massa. Aliquam ac felis ultricies, vestibulum mauris a, ornare nisl. Sed sit amet nisl a orci rutrum efficitur. Phasellus et orci eget lacus vulputate mollis.",
      path: "/service"
    },
    {
      img: serviceImgThree,
      title: "Service Bundle Three",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis. Suspendisse convallis quam eget enim fermentum.",
      path: "/service"
    },
    {
      img: serviceImgFour,
      title: "Service Bundle Four",
      description: "Praesent dapibus enim quis nunc fringilla, vitae gravida arcu aliquam. Sed finibus pulvinar urna, at commodo dolor sodales. Sed sit amet nisl a orci rutrum efficitur.",
      path: "/service"
    },
    {
      img: serviceImgFive,
      title: "Service Bundle Five",
      description: "Nulla facilisi. Mauris fermentum, felis vehicula congue pellentesque, tellus sapien pulvinar nunc, sit amet maximus sapien augue eu lectus. Duis a nulla metus.",
      path: "/service"
    },
    {
      img: serviceImgSix,
      title: "Service Bundle Six",
      description: "Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis. Suspendisse convallis quam eget enim fermentum.",
      path: "/service"
    },
  ]

const AllServices = () => {
    return (
        <div>
          <Helmet>
            <title>React Modern Theme | Our Services</title>
            <meta name="description" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc bibendum augue vel eleifend pharetra. Etiam semper sem a dui pharetra lobortis suspendisse." />
          </Helmet>
          <AnnouncementBar />
          <div>
            <div className="allservice-hero hero">
                    <h1>
                        Our services
                    </h1>
            </div>
          </div>
          <section>
            <div className="services">
            {serviceInfo.map(service => (
              <div className="service-card" key={service.title}>
                <div className="service-card-image">
                  <img src={service.img} alt={service.title} />
                </div>
                <div className="service-card-text">
                  <h2>{service.title}</h2>
                  <p>{service.description}</p>
                  <Link className="button" to={service.path} reloadDocument>
                    Read More
                  </Link>
                </div>
              </div>
            ))}
            </div>
          </section>
        </div>
      )
}

export default pageTransition(AllServices);